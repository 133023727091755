import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Button = ({ title, ...props }) => <Wrapper {...props}>{title}</Wrapper>;

Button.propTypes = {
  title: PropTypes.string,
  borderRadius: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: none;

  ${({
    height,
    width,
    bgColor,
    bgPrimary,
    borderRadius,
    color,
    border,
    fontSize,
    padding,
    margin,
    fontWeight,
    theme,
  }) => css`
    ${height &&
    css`
      height: ${height};
    `};
    ${width &&
    css`
      width: ${width};
    `};
    ${bgColor &&
    css`
      background-color: ${bgColor};
    `};
    ${bgPrimary &&
    css`
      background-color: ${theme.primaryColor};
    `};
    ${borderRadius &&
    css`
      border-radius: ${borderRadius};
    `};
    ${color &&
    css`
      color: ${color};
    `};
    ${border &&
    css`
      border: ${border};
    `};
    ${fontSize &&
    css`
      font-size: ${fontSize};
    `};
    ${fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};
    ${padding &&
    css`
      padding: ${padding};
    `};
    ${margin &&
    css`
      margin: ${margin};
    `};
  `}
`;

export default Button;
