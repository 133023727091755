import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip from '@catalogo/theme-tooltip-box';
import Profile from '@catalogo/ui-americanas-desktop-svg/profile.svg';
import TooltipLogin from './components/tooltip-login';
import { Logged, LoggedOut } from './components/login';

export const Login = ({ cookies = {}, config, history }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { acomNick } = cookies;

  return (
    <Container
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setShowTooltip(false);
        }
      }}
    >
      <Wrapper
        onClick={() => setShowTooltip(true)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
      >
        <Profile width="32px" height="32px" />
        {!acomNick ? <LoggedOut /> : <Logged profile={acomNick} />}
      </Wrapper>
      <Tooltip
        width="auto"
        height="auto"
        show={showTooltip}
        positionType="absolute"
        color="#fff"
        arrowSize={12}
        marginTop={0}
        arrowPosition="72%"
        top="60px"
        right="-57px"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        zIndex={showTooltip ? 9 : 0}
      >
        {showTooltip && (
          <TooltipLogin isLogged={acomNick} zIndex={showTooltip ? 9 : 0} history={history} config={config} />
        )}
      </Tooltip>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  position: relative;
  text-decoration: none;
  color: #fff;
  display: flex;
  height: 45px;
  align-items: center;
  flex: 1;
`;

const Wrapper = styled.button`
  display: flex;
  text-decoration: none;
  width: auto;
  cursor: default;
  position: relative;
  height: 80px;
  align-items: center;
  white-space: nowrap;
  transition: all 0.3s;
  background: none;
  color: #fff;
  border: none;
  z-index: ${({ zIndex }) => zIndex};
`;
