export const formatPrice = number => {
  if (typeof number !== 'number' || isNaN(number)) {
    return null;
  }

  number = number.toFixed(2).split('.');
  number[0] = `${number[0].split(/(?=(?:...)*$)/).join('.')}`;
  return number.join(',');
};

export const removeBRPriceFormat = value => value?.replace('R$ ', '').replace('.', '').replace(',', '.');
