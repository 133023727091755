import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = props => {
  const { className, color, padding, id, children } = props;
  return (
    <Container className={className} color={color} padding={padding} id={id}>
      {children}
    </Container>
  );
};

const Container = styled.main`
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  max-width: 1250px;
  margin: 0 auto;
`;
