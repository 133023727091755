import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';

const TooltipListItem = ({ item, highlighText, isSelected, index, maxWidth = 'none' }) => {
  const splittedTerm = highlighText ? item.name.split(highlighText) : [];

  useEffect(() => {
    if (isSelected) {
      setHeaderContext({
        termSelected: item.name,
      });
    }
  }, [isSelected]);

  const onMouseEnter = index => {
    setHeaderContext({
      tooltipItemSelected: index,
      termSelected: item.name,
    });
  };

  const highlightedText = (array, str) => (array.length > 0 ? highlightRender(array, str) : null);

  const highlightRender = (array, str) =>
    array.reduce((result, item) => (
      <>
        {result}
        {str}
        {item}
      </>
    ));

  return (
    <ListItem isSelected={isSelected} onMouseEnter={() => onMouseEnter(index)} maxWidth={maxWidth}>
      <ListNav to={item.path}>
        {highlighText ? highlightedText(splittedTerm, <ListNavTerm>{highlighText}</ListNavTerm>) : item.name}
      </ListNav>
    </ListItem>
  );
};

export default TooltipListItem;

const ListItem = styled.li`
  margin: 0px;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.grey.darker};
      background-color: ${({ theme }) => theme.grey.lighter};
      outline: none;
      border-radius: 8px;
    `}
`;

const ListNav = styled(Nav)`
  font-size: 14px;
  margin: 0px;
  text-align: left;
  padding: 8px;
  color: ${({ theme }) => theme.grey.medium};
  cursor: pointer;
  color: #333;

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:active {
    color: #333;
    text-decoration: none;
  }
`;

const ListNavTerm = styled.span`
  color: #666;
`;
