import { getIn } from 'seamless-immutable';
import { stringify } from 'query-string';

export const getLink = (product, pfmParams, oneDayDelivery, sellerId, isRec, isMarketEnabled, dataSheet) => {
  const id = getIn(product, ['id'], '');
  const productSlug = getIn(product, ['slug'], '');
  const queryString = getIn(product, ['queryString'], '');
  const pathname = getPathname(id, productSlug);
  const offerIdParam = addOfferIdToQueryString(product);
  const oneDayDeliveryParam = getQueryStringOneDayDelivery(oneDayDelivery);
  const queryParams = getQueryString(queryString);
  const sellerIdParam = addSellerIdToQueryString(sellerId);
  const buyboxTokenParam = addBuyboxTokenToQueryString(product);
  const addChaveAdsParam = addChaveAds(product);

  if (isRec) {
    return pathname.concat(pfmParams, queryParams, offerIdParam);
  }

  if (isMarketEnabled) {
    return getMarketURL(dataSheet, queryString, pfmParams);
  }

  return pathname.concat(
    pfmParams,
    oneDayDeliveryParam,
    queryParams,
    offerIdParam,
    sellerIdParam,
    buyboxTokenParam,
    addChaveAdsParam
  );
};

const getPathname = (id, slug) => (slug ? `/produto/${id}/${slug}?` : `/produto/${id}?`);

const getMarketURL = (dataSheet, queryString, pfmParams) => {
  const nameSlug = dataSheet?.find(item => item?.name === 'Product slug')?.value;
  const storeSlug = dataSheet?.find(item => item?.name === 'Store slug')?.value;

  if (!storeSlug) {
    return 'https://amercado.americanas.com.br';
  }
  return `https://amercado.americanas.com.br/produtos/${storeSlug?.toLowerCase()}?productSlug=${nameSlug}${getQueryStringPfmParams(
    pfmParams
  )}${getQueryString(queryString)}`;
};

const getQueryString = queryString => (queryString ? `&${queryString}` : '');

const getQueryStringPfmParams = pfmParams => (pfmParams ? `&${pfmParams}` : '');

const getQueryStringOneDayDelivery = oneDayDelivery => (oneDayDelivery ? `&${oneDayDelivery}` : '');

const addOfferIdToQueryString = product => {
  const offerId = getIn(product, ['offers', 'result', 0, 'offerId']);
  return offerId ? `&offerId=${offerId}` : '';
};

const addChaveAds = product => {
  const key = product?.ads?.chave;
  return key ? `&chave=${key}` : '';
};

const addSellerIdToQueryString = sellerId => (sellerId ? `&sellerId=${sellerId}` : '');

const addBuyboxTokenToQueryString = product => {
  const buyboxToken = product?.offers?.meta?.buyboxToken;

  return buyboxToken ? `&buyboxToken=${buyboxToken}` : '';
};

export const getOneDayDelivery = oneDayDeliveryFiltered => {
  if (!oneDayDeliveryFiltered) {
    return '';
  }

  const oneDayDelivery = {
    oneDayDeliveryFiltered: oneDayDeliveryFiltered,
  };

  return stringify(oneDayDelivery);
};

export const getPfmParams = (match, positionCard, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: match?.params?.term,
    pfm_page: pageType,
    pfm_pos: 'grid',
    pfm_type: `${pageType}_page`,
  };

  return stringify(params);
};

export const getPfmParamsAds = (positionCard, position, publication, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_page: pageType,
    pfm_pos: position,
    pfm_type: 'vit_ads',
    nmOrigem: `${position}-${publication?.title}`,
  };

  return stringify(params);
};

export const getPfmParamsRecs = (positionCard, position, publication, pageType, qsKey) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: publication?.title,
    pfm_page: pageType,
    pfm_pos: position,
    pfm_type: publication?.rule ? 'vit_recommendation' : 'vit_spacey',
    chave: qsKey,
  };

  return stringify(params);
};

export const getPfmParamsMercado = (match, positionCard, pageType) => {
  const params = {
    pfm_index: positionCard + 1,
    pfm_carac: match?.params?.term,
    pfm_page: pageType,
    pfm_pos: 'grid',
    pfm_type: `${pageType}_page`,
    acomSearch: true,
    acomTerm: match?.params?.term,
  };

  return stringify(params);
};
