import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import { timerEventMetric } from '@catalogo/service-americanas-header/src/helpers/metrics';
import { TrendingDepartmentsDropDown } from './trending-departments-drop-down';
import TrendingDepartmentsItemWithMenu from './trending-departments-item-with-menu';

let timeout = 0;

export const MenuTrendingDepartments = ({ trendingDepartments, triggerOverlay, dispatchMetrics }) => {
  const [item, setItem] = useState({});
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);
  const debounced = useDebounce(hover, hover ? 300 : 0);
  const [canFireMetrics, setCanFireMetrics] = useState(true);

  useEffect(() => {
    setShow(debounced);
    triggerOverlay(debounced);
  }, [debounced]);

  if (!trendingDepartments) {
    return null;
  }

  const mouseLeave = () => {
    setItem({});
    triggerOverlay(false);
    setHover(false);
    clearTimeout(timeout);
  };

  const mouseEnter = (item, index, hasChildrens, fireEvent) => {
    clearTimeout(timeout);
    if (canFireMetrics && fireEvent) {
      timeout = timerEventMetric(dispatchMetrics, 'menutemp', 'menu-tts', item?.title, () => setCanFireMetrics(false));
    }
    debounced && hasChildrens && triggerOverlay(true);
    setHover(hasChildrens);
    setItem({ ...item, index, hasChildrens });
  };

  const Dropdown = (tt, index) => (
    <TrendingDepartmentsDropDown
      title={tt?.title}
      link={tt?.link}
      list={tt?.children}
      show={index + 1 === item?.index && show}
      position={index === 0 ? 'first' : index + 1 < 7 ? 'left' : 'right'}
      image={tt?.image}
      imageUrl={tt?.imageUrl}
      alt={tt?.alt}
      zIndex={10}
      dispatchMetrics={dispatchMetrics}
    />
  );

  return (
    <List onMouseLeave={mouseLeave}>
      {trendingDepartments.map((tt, index) => {
        const hasChildrens = !!tt?.children?.length;
        return (
          <TrendingDepartmentsItemWithMenu
            key={tt.id}
            item={tt}
            highlight={item.id === tt.id}
            onMouseEnter={() => mouseEnter(tt, index + 1, hasChildrens, true)}
            onFocus={() => mouseEnter(tt, index + 1, hasChildrens, true)}
            last={index === trendingDepartments.length - 1}
            dispatchMetrics={dispatchMetrics}
          >
            {hasChildrens ? Dropdown(tt, index) : <></>}
          </TrendingDepartmentsItemWithMenu>
        );
      })}
    </List>
  );
};

export default MenuTrendingDepartments;

const List = styled.div`
  width: 100%;
  background-color: #fff;
  z-index: 1;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  z-index: 10;
  position: relative;
  height: 100%;
  white-space: nowrap;
`;
