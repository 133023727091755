import { parse, stringify } from 'querystring';
import React, { useState } from 'react';
import withHeaderLocation from '@catalogo/service-americanas-header/src/hocs/header-location';
import styled from 'styled-components';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import Location from '@catalogo/ui-americanas-desktop-svg/location.svg';
import CepTooltip from './components/cep-tooltip';

export const HeaderAddress = ({ address, history, dispatchMetrics, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(address ? false : true);
  const [firstRender, setFirstRender] = useState(true);
  const title = 'as melhores ofertas e condições de frete para a sua região :)';
  const debounceTooltip = useDebounce(showTooltip, 300);
  const isShowOverlay = address => !!address || !firstRender;
  const search = history?.location?.search || '';
  const parsedQs = parse(search.replace('?', ''));

  const handleTooltip = address => {
    if (!address) {
      return setShowTooltip(true);
    }
    return null;
  };
  return (
    <CepWrapper>
      <AdressWrapper
        onMouseEnter={() => {
          setFirstRender(false);
          handleTooltip(address);
        }}
        onClick={() =>
          history.push(
            {
              search: `?${stringify(parsedQs)}`,
              hash: '#modal-select-address',
            },
            { title }
          )
        }
        zIndex={debounceTooltip ? 9 : 0}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <TextWrapper>
          <IconLocation />
          <Text>{address ? address : <strong>informe seu CEP</strong>}</Text>
        </TextWrapper>
      </AdressWrapper>
      <CepTooltip
        address={address}
        setCookie={props?.setCookie}
        cookies={props?.cookies}
        show={debounceTooltip}
        dispatchMetrics={dispatchMetrics}
        setShowTooltip={setShowTooltip}
        hasOverlay={isShowOverlay()}
        login="https://cliente.americanas.com.br/minha-conta/entrar?guest=true&next=https%3A%2F%2Fwww.americanas.com.br%2F"
      />
    </CepWrapper>
  );
};

const AdressWrapper = styled.button`
  cursor: pointer;
  color: #fff;
  display: flex;
  font-size: 14px;
  align-items: center;
  background: none;
  max-width: 270px;
  position: relative;
  transition: all 0.3s;
  background: none;
  border: none;
  z-index: ${({ zIndex }) => zIndex};
`;

const TextWrapper = styled.div`
  display: flex;
  padding-left: 4px;
`;

const IconLocation = styled(Location)`
  width: 14px;
  height: 18px;
  margin-right: 7px;
  fill: #fff;
`;

const Text = styled.p`
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  margin: 0;
`;

const CepWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export default withHeaderLocation(HeaderAddress);
