import React from 'react';
import PropTypes from 'prop-types';
import { bindAll } from '@catalogo/core-bind-all';
import { SwitchComponent } from '@catalogo/core-switch-component';
import styled from 'styled-components';
import { withRouter } from 'react-router';

export class BlackboxPosition extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ['renderComponent']);
  }

  renderComponent(publication) {
    const { type } = publication;
    const { routeId, position } = this.props;

    return (
      <SwitchComponent
        {...this.props}
        routeId={routeId}
        component={type}
        publication={publication}
        renderComponent={this.renderComponent}
        position={position}
      />
    );
  }
  renderLink() {
    const { publication } = this.props;
    if (!publication) {
      return null;
    }

    return (
      <span>
        -&nbsp;
        <a
          rel="noreferrer"
          target="_blank"
          href={`https://spacey.metaplane.cloud/publications/${publication.publicationId}`}
        >
          editar
        </a>
      </span>
    );
  }

  renderDebug() {
    const { position } = this.props;

    return (
      <BoxInfo variant="simple">
        <span>
          {` ${position}`}
          &nbsp;
          {!/^home/.exec(position) && this.renderLink()}
        </span>
      </BoxInfo>
    );
  }

  render() {
    const { publication, position, location } = this.props;

    if (!publication) {
      return null;
    }

    if (location.search.indexOf('log=positions') > -1) {
      return (
        <WrapperDebug data-position={position}>
          {this.renderDebug()}
          {this.renderComponent(publication)}
        </WrapperDebug>
      );
    }

    return <DivPosition data-position={position}>{this.renderComponent(publication)}</DivPosition>;
  }
}

const DivPosition = styled.div`
  max-width: 100%;
`;

const WrapperPosition = styled.div`
  width: 100%;
`;

const BoxInfo = styled.div`
  border: 1px solid #ffb100;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-bottom: 10px;
  background: #ffe6a0;
  z-index: 9999;
`;

const WrapperDebug = styled(WrapperPosition)`
  position: relative;
  border: 1px solid #ccc;
  min-height: 53px;
  margin: 10px 0;
`;

BlackboxPosition.propTypes = {
  publication: PropTypes.object,
  location: PropTypes.object,
  position: PropTypes.string,
  routeId: PropTypes.string,
};

export default withRouter(BlackboxPosition);
