import React from 'react';
import styled, { css } from 'styled-components';

const ProductName = ({ name, className }) => <Name className={className}>{name}</Name>;

const Name = styled.h3`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xs};
    text-transform: lowercase;
    font-weight: 400;
    line-height: 17px;
    max-height: 69px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;

export default ProductName;
