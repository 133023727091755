import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import ProductCard from '@catalogo/ui-americanas-desktop-product-card-suggestion';
import { useHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import { resetProductItemSelected } from '@catalogo/service-americanas-header/src/helpers/keyboad-navigation';

const ProductList = ({ products, className }) => {
  const filteredProducts = products.filter(product => !!product.product).slice(0, 3);
  const { productItemSelected } = useHeaderContext();
  const shouldResetTooltipItemSelected = () =>
    productItemSelected > filteredProducts.length - 1 || productItemSelected < 0;

  useEffect(() => {
    /* istanbul ignore next */
    if (shouldResetTooltipItemSelected()) {
      resetProductItemSelected();
    }
  }, [productItemSelected]);

  return (
    <Wrapper className={className}>
      <Title>Produtos sugeridos:</Title>
      <Container>
        {filteredProducts.map((product, index) => (
          <Card key={product.product.id}>
            <ProductCard className="product-card-header" product={product} isSelected={index == productItemSelected} />
          </Card>
        ))}
      </Container>
    </Wrapper>
  );
};

export default ProductList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  align-items: baseline;
  align-content: stretch;
`;

const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.grey.dark}};
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    flex: 0 0 auto;
    padding: 10px 8px;
    width: 100%;
    margin: 0;
  `}
`;
const Card = styled.div`
  width: 33.33%;

  & .product-card-header {
    max-width: 138px;
  }
`;
