import React, { useState, useEffect } from 'react';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import SuggestionBox from '@catalogo/ui-americanas-desktop-header/src/components/top/search/components/suggestion-box';
import HistoricBox from '@catalogo/ui-americanas-desktop-header/src/components/top/search/components/historic-box';

export const SearchTooltipHandler = ({ hasAlreadyShown, hasInputFocus, setHasInputFocus, typedTerm }) => {
  const debounce = useDebounce(typedTerm, 500);
  const [shouldAnimateHistoric, setShouldAnimateHistoric] = useState(true);
  const [shouldShowHistoric, setShouldShowHistoric] = useState(false);

  useEffect(() => {
    setShouldAnimateHistoric(!hasAlreadyShown && !typedTerm);
    setShouldShowHistoric(hasInputFocus && !typedTerm);
  }, [debounce]);

  return shouldAnimateHistoric || shouldShowHistoric ? (
    <HistoricBox show={hasInputFocus} showTooltipBox={setHasInputFocus} zIndex={hasInputFocus ? 9 : 0} />
  ) : (
    <SuggestionBox
      term={typedTerm}
      hasFocus={hasInputFocus}
      showTooltipBox={setHasInputFocus}
      zIndex={hasInputFocus ? 9 : 0}
      skip={!hasInputFocus}
    />
  );
};
