import React from 'react';
import styled from 'styled-components';
import ArrowSvg from '@catalogo/ui-americanas-desktop-svg/arrow.svg';

export const Logged = ({ profile }) => (
  <Text>
    olá{profile ? `, ${decodeURI(profile)}` : '!'} <br />{' '}
    <Span>
      minha conta
      <ArrowUI fill="#fff" width="10px" height="6px" />
    </Span>
  </Text>
);

export const LoggedOut = () => (
  <Text>
    olá, faça seu login <br />{' '}
    <Span>
      ou cadastre-se
      <ArrowUI fill="#fff" width="10px" height="6px" />
    </Span>
  </Text>
);

const Text = styled.span`
  margin-left: 8px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
`;

const ArrowUI = styled(ArrowSvg)`
  margin-left: 12px;
`;

const Span = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
