export const fmtDecodedAddress = address => {
  const fmtAddress = address.split('_').map(field => field.replace(/-/g, ' '));
  const city = fmtAddress[fmtAddress.length - 2];
  const state = fmtAddress[fmtAddress.length - 1];

  if (fmtAddress.length >= 3) {
    let addressResult = `${city} - ${state}`;

    if (fmtAddress.length >= 4) {
      const street = fmtAddress[1];
      addressResult = `${street}, ${city} - ${state}`;
      // eslint-disable-next-line max-depth
      if (fmtAddress.length === 5) {
        const neighborhood = fmtAddress[2];
        addressResult = `${street}, ${neighborhood} - ${city} - ${state}`;
      }
    }
    return decodeURI(decodeURI(addressResult));
  }
};

export const getAddressOf = (AddressOfCustomer, AddressOfZipCode, persistentAddress) => {
  if (AddressOfCustomer) {
    const addressMain = AddressOfCustomer?.customer?.addresses?.find(addresse => addresse.main);
    return fmtAddress(addressMain);
  }

  if (AddressOfZipCode) {
    const addressZipCode = AddressOfZipCode?.region;
    return fmtAddress(addressZipCode);
  }

  if (persistentAddress && !isUndefinedStr(persistentAddress)) {
    return persistentAddress.replace(/null_/g, '');
  }

  return null;
};

export const extractOf = cookies => {
  const id = cookies['customer.id'];
  const token = cookies['customer.api.token'];
  const persistentCep = cookies?.persistentCep;
  const persistentAddress = cookies?.persistentAddress;

  return { id, token, persistentCep, persistentAddress };
};

export const isUndefinedStr = value => value === 'undefined';

const fmtAddress = address => {
  if (address) {
    const { zipCode, cep, address: street, neighborhood, city, state } = address;
    const cepValue = zipCode ?? cep;
    return encodeURI(
      street ? `${cepValue}_${street}_${neighborhood}_${city}_${state}` : `${cepValue}_${city}_${state}`
    );
  }

  return null;
};
