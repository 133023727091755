import { useQuery, useLazyQuery } from '@apollo/client';
import { getHeader } from '../queries/header.graphql';

export const useHeader = (variables: Record<string, string>) => {
  const { loading, data, error } = useQuery(getHeader, {
    variables,
  });

  return { loading, data, error };
};

export const useMenu = () => {
  const [fetchMenu, { loading, data, error }] = useLazyQuery(getHeader);

  return { fetchMenu, loading, data, error };
};
