import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { formatPrice } from '@catalogo/core-helpers/price';
import { Product } from './product';

export const Cart = ({ products, total }) => {
  if (!products?.length) {
    return (
      <EmptyContainer>
        <EmptyBasketText>sua cesta está vazia</EmptyBasketText>
      </EmptyContainer>
    );
  }

  return (
    <Container>
      <BasketText>minha cesta</BasketText>
      <ProductList>
        {products.map(product => (
          <ProductItem key={product.id}>
            <Product {...product} />
          </ProductItem>
        ))}
      </ProductList>
      <Total>
        <ProductText>
          Total (valor sem frete): <Price>R$ {formatPrice(total)}</Price>
        </ProductText>
        <BasketLink to="https://sacola.americanas.com.br/#/basket">ver minha cesta</BasketLink>
      </Total>
    </Container>
  );
};

const Container = styled.div`
  min-height: 124px;
  width: 350px;
  height: auto;
  max-height: 370px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BasketText = styled.p`
  font-size: 18px;
  line-height: 16px;
  color: #666;
  flex: 0 0 auto;
  width: calc(100% - 64px);
  margin: 0 32px;
  border-bottom: 1px solid #e5e5e5;
  padding: 28px 0 12px 5px;
`;

const ProductList = styled.ul`
  padding: 7px 32px 0;
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  max-height: 206px;
  overflow: auto;
`;

const ProductItem = styled.li`
  padding: 10px 0;
  min-height: 54px;
  box-sizing: content-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Total = styled.div`
  border-top: 1px solid #999;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0px -4px 3px -3px rgb(0 0 0 / 12%);

  flex: 0 0 auto;
  width: calc(100% - 64px);
  margin: 0 32px;
`;

const ProductText = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

const BasketLink = styled(Nav)`
  padding: 6px 21px;
  color: #fff;
  background-color: #f80032;
  font-size: 16px;
  font-weight: bold;
  width: fit-content;
  border-radius: 8px;
  margin-bottom: 38px;

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const EmptyContainer = styled.div`
  min-width: 364px;
  min-height: 124px;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyBasketText = styled.span`
  font-size: 20px;
  color: #666;
`;
