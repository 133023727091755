import styled, { css } from 'styled-components';

export const Text = styled.span`
  ${props =>
    props.numberOfLines &&
    css`
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: ${props.numberOfLines};
      -webkit-box-orient: vertical;
      word-break: break-word;
    `}
`;
