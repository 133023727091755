import React from 'react';
import styled from 'styled-components';
import { formatPrice } from '@catalogo/core-helpers/price';
import { Nav } from '@catalogo/core-nav';

export const Product = ({ name, quantity, id, salesPrice, image }) => (
  <ProductLink to={`/produto/${id}`}>
    <ProductImage src={image} />
    <ProductWrapper>
      <ProductName>{name}</ProductName>
      <InformationContainer>
        <ProductText>Quantidade: {quantity}</ProductText>
        <Price>R$ {formatPrice(salesPrice)}</Price>
      </InformationContainer>
    </ProductWrapper>
  </ProductLink>
);
const ProductLink = styled(Nav)`
  display: flex;
  align-items: center;
  width: 100%;
  color: #000;

  &:hover,
  &:active {
    color: #000;
    text-decoration: none;
  }
`;

const ProductWrapper = styled.div`
  display: block;
  overflow: hidden;
  width: 100%;
`;

const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

const ProductImage = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 15px;
  z-index: -1;
`;

const ProductName = styled.p`
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ProductText = styled.p`
  font-size: 14px;
`;

const Price = styled.span`
  font-size: 14px;
  color: #666;
`;
