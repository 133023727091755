import React from 'react';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';
import { withConfig } from '@catalogo/core-connect/config';
import { withMetrics } from '@catalogo/core-metrics';
import { fmtCart } from '../helpers/cart';
import { useGetCart } from '../hooks/get-cart';
import { ProductType, WithCartProps } from '../types';

export const withCart = (Component: React.FC) => (props: WithCartProps) => {
  const { data, loading, error } = useGetCart(props?.cookies['cart.id']);

  const products = fmtCart(data);
  const cartSize = products.reduce((size: number, product: ProductType) => size + product.quantity, 0);
  const cartProps = {
    products,
    loading,
    error,
    cartSize,
    total: data?.cart?.total,
  };

  return <Component {...cartProps} {...props} />;
};

export default compose(withMetrics, withConfig, withCookies, withCart);
