import { getIn } from 'seamless-immutable';
import { sendBeacon } from '@catalogo/core-send-beacon';
import { isPrime, oneDayDeliveryMsg } from './image-and-stamps';

const sendMetricsAds = product => {
  if (!product) {
    return null;
  }

  const { trackingUrl, ads } = product;
  const urlTrackingAd = trackingUrl || ads?.trackingUrl;

  if (urlTrackingAd) {
    sendBeacon(urlTrackingAd);
  }
};
const sendMetrics = (product, position, index, dispatchMetrics) => {
  if (!product) {
    return null;
  }
  const { id, offers } = product;
  const prime = isPrime(product);
  const bestPaymentOption = getIn(offers, ['result', 0, 'bestPaymentOption']);
  const salesPrice = getIn(bestPaymentOption, ['minInstallment', 0, 'total']) || getIn(bestPaymentOption, ['price']);
  const oneDayDelivery = oneDayDeliveryMsg(product);
  const offerId = getIn(offers, ['result', 0, 'offerId'], '');
  dispatchMetrics('product:click', { position, id, index, salesPrice, prime, oneDayDelivery, offerId });
};

export const sendMetricsBrand = (dispatchMetrics, sponsorName) => {
  dispatchMetrics('event:track', {
    event: 'brandingbusca',
    category: 'branding-busca',
    label: sponsorName,
    action: 'clique',
  });
};

export const sendMetricsOfVitrineAds = dispatchMetrics => {
  dispatchMetrics('event:track', {
    category: 'product',
    label: 'patrocinado-vitrine',
    action: 'click',
  });
};

export const handleMetrics = (product, position, index, dispatchMetrics, isBannerVitrineAds) => {
  sendMetrics(product, position, index, dispatchMetrics);
  sendMetricsAds(product);
  isBannerVitrineAds && sendMetricsOfVitrineAds(dispatchMetrics);
};
