import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import ProductInfo from '../molecules/product-info';

const InStockCard = props => {
  const { product, url, isSelected } = props;

  useEffect(() => {
    if (isSelected) {
      setHeaderContext({
        formAction: '/produto',
        termSelected: product.product.id,
      });
    }
  }, [isSelected]);

  return (
    <Wrapper>
      <Link to={url} isSelected={isSelected}>
        <ImageInfo {...props} />
        <ProductInfo {...props} />
        <PriceInfo {...props} />
      </Link>
    </Wrapper>
  );
};

export default InStockCard;

const Wrapper = styled.div``;

const Link = styled(Nav)`
  display: block;
  outline: none;
  position: relative;
  padding-bottom: 4px;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 8px;
        border: 2px solid ${theme.primaryColor};
      }
    `}
`;
