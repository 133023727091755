export const fmtCart = cartData => {
  if (!cartData) {
    return [];
  }
  return cartData.cart.lines.map(item => ({
    id: item.product.id,
    image: item.product.image,
    name: item.product.name,
    sku: item.product.sku,
    quantity: item.offer.quantity,
    salesPrice: item.offer.salesPrice,
  }));
};
