export const formatProductRec = ({ product, publication, position, pageType, trackingUrl, queryString, isAd }) => ({
  ...product,
  trackingUrl,
  queryString,
  pfm: {
    publication,
    position,
    positionCard: 0,
    pageType,
  },
  offers: {
    ...product?.offers,
    result: mountOfferResultRec(product?.offers?.result, isAd),
  },
  compliance: {
    nursling: product?.compliance?.nursling,
  },
});

const mountOfferResultRec = (result, isAd) => {
  if (result && result[0]) {
    return [
      {
        ...result[0],
        stamps: {
          ...result[0]?.stamps,
          ad: isAd,
        },
      },
    ];
  }
  return [];
};
