import React, { HTMLProps } from 'react';
import { useHistoric } from '../hooks/autocomplete';
import { fmtSuggestions } from '../helpers/autocomplete';

export const withHistoric = (Component: React.FC) => ({ ...props }: HTMLProps<HTMLSpanElement>) => {
  const data = useHistoric();
  const historic = fmtSuggestions(data);

  const historicProps = {
    data: historic,
    ...props,
  };

  return <Component {...historicProps} />;
};

export default withHistoric;
