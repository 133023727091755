import { useEffect } from 'react';
import { useHeaderContext } from '@catalogo/service-americanas-header/src/context/header';
import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';

export function useKeyboardNavigation() {
  const keyLeftArrow = 37;
  const keyUpArrow = 38;
  const keyRightArrow = 39;
  const keyDownArrow = 40;

  const { tooltipItemSelected, productItemSelected } = useHeaderContext();

  const onKeyDown = (event: KeyboardEvent) => {
    const { keyCode } = event;

    if (keyCode === keyUpArrow) {
      setHeaderContext({
        tooltipItemSelected: tooltipItemSelected - 1,
        productItemSelected: -1,
      });
    }

    if (keyCode === keyDownArrow) {
      setHeaderContext({
        tooltipItemSelected: tooltipItemSelected + 1,
        productItemSelected: -1,
      });
    }

    if (keyCode === keyLeftArrow) {
      setHeaderContext({
        tooltipItemSelected: -1,
        productItemSelected: productItemSelected - 1,
      });
    }

    if (keyCode === keyRightArrow) {
      setHeaderContext({
        tooltipItemSelected: -1,
        productItemSelected: productItemSelected + 1,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, true);
    return () => {
      document.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown]);

  return [tooltipItemSelected, productItemSelected];
}
