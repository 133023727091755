import React from 'react';
import styled from 'styled-components';
import LasaLogo from '@catalogo/ui-americanas-desktop-svg/logo-main.svg';

const Logo = ({ className }) => (
  <VisibleLogo className={className} title="americanas" href="/">
    <LasaLogoUI fill="#fff" width={163} height={32} />
  </VisibleLogo>
);

export default Logo;

const VisibleLogo = styled.a`
  display: flex;
  flex: 1;
`;

const LasaLogoUI = styled(LasaLogo)`
  transform: translateX(-5px);
  margin-right: -5px;
`;
