import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import StoreIcon from '@catalogo/ui-americanas-desktop-svg/header-store.svg';
import HeartIcon from '@catalogo/ui-americanas-desktop-svg/header-heart-desk.svg';
import ShoppingBasketIcon from '@catalogo/ui-americanas-desktop-svg/header-basket-desk.svg';
import { Nav } from '@catalogo/core-nav';
import Tooltip from '@catalogo/theme-tooltip-box';
import withCart from '@catalogo/service-americanas-header/src/hocs/cart';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import { Cart } from './cart';

export const IconMenu = props => {
  const [showTooltip, setShowTooltip] = useState(false);

  const debouncedTime = 500;
  const debouncedTooltip = useDebounce(showTooltip, debouncedTime);
  const { cartSize, products, total } = props;

  return (
    <IconMenuContainer>
      <StoreLink to="https://nossaslojas.americanas.com.br/?chave=o2o_hm_00_0_0_nossaslojas">
        <StoreIconUI />
      </StoreLink>
      <FavoriteLink to="https://www.americanas.com.br/listas/favoritos">
        <HeartIconUI fill="#fff" />
      </FavoriteLink>
      <ShoppingBasketLink
        onClick={() => setShowTooltip(!showTooltip)}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            setShowTooltip(false);
          }
        }}
      >
        <ShoppingBasketWrapper zIndex={showTooltip ? 9 : 0} showTooltip={showTooltip}>
          <ShoppingBasketIconUI onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
          <BagdeBasketItems>{cartSize}</BagdeBasketItems>
        </ShoppingBasketWrapper>
        <Tooltip
          width="fit-content"
          show={debouncedTooltip}
          positionType="absolute"
          color="#fff"
          arrowSize={10}
          arrowPosition="86%"
          zIndex={9}
          top="30px"
          right="-30px"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          close={() => setTimeout(() => setShowTooltip(false), debouncedTime)}
        >
          <Cart products={products} total={total} />
        </Tooltip>
      </ShoppingBasketLink>
    </IconMenuContainer>
  );
};

export default withCart(IconMenu);

const IconMenuContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  padding: 0 12px 0 48px;
  flex: 1;
`;

const StoreIconUI = styled(StoreIcon)`
  width: 24px;
  height: 22px;
  transition: all 0.3s;
`;

const StoreLink = styled(Nav)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${StoreIconUI} {
    transform: scale(1.25);
  }
`;

const HeartIconUI = styled(HeartIcon)`
  width: 32px;
  height: 32px;
  transition: all 0.3s;
`;

const FavoriteLink = styled(Nav)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 24px;

  &:hover ${HeartIconUI} {
    transform: scale(1.25);
  }
`;

const ShoppingBasketLink = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 24px;
  background: none;
  border: none;
`;

const ShoppingBasketWrapper = styled.span`
  cursor: pointer;
  transition: z-index 0.3s;
  z-index: ${({ zIndex }) => zIndex};

  ${({ showTooltip }) =>
    !showTooltip &&
    css`
      transition: all 0.3s;
      transition-delay: 1s;
    `}

  &:hover {
    div {
      animation: pop 0.6s;
      animation-iteration-count: 1;
    }
  }

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ShoppingBasketIconUI = styled(ShoppingBasketIcon)`
  width: 25px;
  height: 24px;
  position: 'relative';
`;

const BagdeBasketItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.red.dark};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-family: 'Roboto', sans-serif;
  top: -5px;
  right: -8px;
  pointer-events: none;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.3);
  }
`;
