import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutofStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfo {...props} />
      <ProductInfo {...props} />
      <OutOfStockWrapper>
        <OutOfStockText>Ops! Já vendemos todo o estoque.</OutOfStockText>
      </OutOfStockWrapper>
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div`
  margin: 0px auto;
`;

const OutOfStockWrapper = styled.div`
  color: ${({ theme }) => theme.grey.dark};
  padding: 0 5px 16px;
  white-space: normal;
  border-radius: 4px;
`;

const OutOfStockText = styled.span`
  font-size: 12px;
`;

export default OutofStockCard;
