import React from 'react';
import PropTypes from 'prop-types';

export const NoImage = ({ width = '51', height = '44', fill = '#c9c9c9' }) => (
  <svg width={width} height={height} viewBox="0 0 51.08 44.123">
    <g transform="translate(-0.31 -0.92)">
      <g transform="translate(0.31 0.92)">
        <path
          fill={fill}
          d="M3.874,4.495V35.9l7.579-6.5a2.238,2.238,0,0,1,3.253,0l5.336,5.336,12.314-12.3a2.238,2.238,0,0,1,3.253,0L47.826,33.578V4.495ZM48.9,38.86a2.232,2.232,0,0,1-.462-.322L33.977,25.161l-12.3,13.377a2.244,2.244,0,0,1-3.253,0l-5.346-6.409-8.266,9.34H48.9ZM4.948,45.043A4.655,4.655,0,0,1,.31,40.395V5.569A4.655,4.655,0,0,1,4.948.92h41.8A4.655,4.655,0,0,1,51.39,5.569V40.395a4.655,4.655,0,0,1-4.638,4.649Z"
          transform="translate(-0.31 -0.92)"
        />
      </g>
    </g>
  </svg>
);

NoImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};
