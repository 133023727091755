/* eslint max-statements: [2, 20] */
import { useQuery, useLazyQuery } from '@apollo/client';
import { addMonths } from '@catalogo/core-cookies/src/helpers';
import { getAddressOfCustomer, getAddressOfZipCode } from '../queries/get-address.graphql';
import { getCoordinates } from '../queries/get-coordinates.graphql';
import { getAddressOf, fmtDecodedAddress, extractOf, isUndefinedStr } from '../helpers/header-location';
import { useReturnMemo } from '../helpers/header';
import { CookiesType, SetCookieType } from '../types';

export const useGetAddress = (cookies: CookiesType, setCookie: SetCookieType) => {
  const { id, token, persistentCep, persistentAddress } = extractOf(cookies);
  const hasPersistentAddress = !!persistentAddress && !isUndefinedStr(persistentAddress);
  const hasSameZipCode = hasPersistentAddress && !!persistentCep ? persistentAddress.includes(persistentCep) : false;
  const skipFetchAddressOfCustomer = !id || !token || (!!hasPersistentAddress && !!persistentCep);

  const { data: AddressOfCustomer } = useQuery(getAddressOfCustomer, {
    variables: {
      id,
      token,
    },
    skip: !!skipFetchAddressOfCustomer,
    ssr: false,
  });

  const { data: AddressOfZipCode } = useQuery(getAddressOfZipCode, {
    variables: {
      cep: persistentCep,
    },
    skip: !!hasSameZipCode || !persistentCep,
    ssr: false,
  });

  const addressFmt = useReturnMemo(getAddressOf, [AddressOfCustomer, AddressOfZipCode, persistentAddress]);

  if (addressFmt) {
    const zipCode = addressFmt.split('_')[0];
    if (!persistentCep && zipCode.length === 8) {
      setCookie('persistentCep', zipCode, addMonths(new Date(), 12));
    }
    if (!persistentAddress || isUndefinedStr(persistentAddress) || !hasSameZipCode) {
      setCookie('persistentAddress', addressFmt);
    }

    return fmtDecodedAddress(addressFmt);
  }

  return null;
};

export const useGetCoord = ({ cep }: { cep: string }) => {
  const [fetchCoordinates, { data: geoData, loading: geoLoading, error }] = useLazyQuery(getCoordinates, {
    variables: { cep },
    ssr: false,
  });

  return { fetchCoordinates, geoData, geoLoading, error };
};
