import React from 'react';
import styled from 'styled-components';
import { Text } from '@catalogo/theme-text';
import { bindAll } from '@catalogo/core-bind-all';
import { withCookies } from '@catalogo/core-connect/cookies';
import AlertWarning from '@catalogo/ui-americanas-desktop-alert-warning';
import GeneralLoader from '@catalogo/ui-americanas-desktop-generalloader';

class FreightField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
    bindAll(this, ['handleChange', 'handlePress']);
  }

  componentDidMount() {
    const { cookies } = this.props;
    if (cookies.persistentCep && cookies.persistentCep === this.state.inputValue) {
      this.formatMaskAndSaveValue(cookies.persistentCep);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cookies !== this.props.cookies) {
      const { cookies } = this.props;
      if (cookies.persistentCep && cookies.persistentCep === this.state.inputValue) {
        this.formatMaskAndSaveValue(cookies.persistentCep);
      }
    }
  }

  formatMaskAndSaveValue(value = '') {
    const formatedValue = this.formatMask(value, '00000-000');
    this.setState({ inputValue: formatedValue });
  }

  formatCep(cep) {
    return cep.replace('-', '');
  }

  formatMask(str, mask) {
    const numbers = str.replace(/\D/g, '');
    let specialCharCount = 0;

    if (this.isValid(str, mask)) {
      return str;
    }

    return mask
      .split('')
      .map((char, index) => {
        const fieldChar = numbers[index - specialCharCount];
        const isSpecialChar = ['-', '.', '/', '(', ')', ' '].indexOf(char) > -1;

        if (!fieldChar) {
          return null;
        }

        if (isSpecialChar) {
          specialCharCount++;
          return char;
        }

        return fieldChar;
      })
      .join('');
  }

  isValid(str, mask) {
    const masked = str.replace(/\d/g, '0');
    return mask.indexOf(masked) > -1;
  }

  hasValidCep() {
    const { inputValue } = this.state;
    const cep = this.formatCep(inputValue);

    return cep.length === 8;
  }
  handlePress(event) {
    event.preventDefault();

    if (this.hasValidCep()) {
      const { handleSubmit, setCep } = this.props;
      const { inputValue } = this.state;

      setCep(this.formatCep(inputValue));

      if (handleSubmit && typeof handleSubmit === 'function') {
        handleSubmit();
      }
    }
  }

  handleChange(event) {
    this.formatMaskAndSaveValue(event.target.value);
  }

  sendMetrics(dispatchMetrics, label) {
    dispatchMetrics('event:track', {
      category: 'trocar-endereco',
      action: 'erro',
      label,
    });
  }

  onError(dispatchMetrics, label) {
    this.sendMetrics(dispatchMetrics, label);
    return <AlertWarning />;
  }

  render() {
    const { inputValue } = this.state;
    const {
      showZipCodeAlert,
      loading,
      placeholder = 'digite o seu CEP',
      className,
      onFocus,
      onBlur,
      dispatchMetrics,
      error,
    } = this.props;
    return (
      <>
        <Form onSubmit={this.handlePress} className={className}>
          <WrapperInput>
            <InputUI
              placeholder={placeholder}
              value={inputValue}
              onChange={this.handleChange}
              type="tel"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </WrapperInput>
          <WrapperButton>
            {loading ? (
              <GeneralLoader />
            ) : (
              <ButtonUI type="submit">
                <TextUI>ok</TextUI>
              </ButtonUI>
            )}
          </WrapperButton>
        </Form>
        {showZipCodeAlert && this.onError(dispatchMetrics, error)}
      </>
    );
  }
}

const InputUI = styled.input`
  display: flex;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.grey.light};
  border-radius: 4px;
  width: 100%;
  height: 45px;
  color: ${({ theme }) => theme.grey.dark};
  font-size: ${({ theme }) => theme.fontSize['sm']};
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 19px;
  padding: 14px 12px 15px;
  margin: 0px;

  :focus,
  :hover {
    outline: 0px;
    border-color: ${({ theme }) => theme.grey.medium};
  }

  ::placeholder {
    color: ${({ theme }) => theme.grey.medium};
  }
`;

const ButtonUI = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  margin: 0px;

  :active,
  :focus {
    outline: 0px;
    text-decoration: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.grey.lighter};
    cursor: pointer;
  }
`;

const TextUI = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize['xs']};
  font-weight: bold;
  line-height: 17px;
  color: ${({ theme }) => theme.primaryColor};
`;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const WrapperInput = styled.div`
  width: 100%;
  margin-right: 10px;
`;

const WrapperButton = styled.div`
  min-width: 47px;
`;

export default withCookies(FreightField);
