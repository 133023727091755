import React from 'react';
import styled from 'styled-components';
import withSuggestions from '@catalogo/service-americanas-header/src/hocs/suggestions';
import TooltipBox from '@catalogo/theme-tooltip-box';
import ProductList from './product-list';
import SearchTooltip from './search-tooltip';

export const SuggestionBox = ({ products, suggestions, show, hasData, showTooltipBox, zIndex }) => {
  if (!hasData) {
    return null;
  }
  return (
    <Container>
      <TooltipBox
        show={show}
        close={showTooltipBox}
        positionType="absolute"
        top="10px"
        left="0"
        arrowPosition="30px"
        zIndex={zIndex}
      >
        <Wrapper>
          <SearchTooltipUI title="você quis dizer:" items={suggestions} type="suggestion" />
          <ProductListUI products={products} />
        </Wrapper>
      </TooltipBox>
    </Container>
  );
};

export default withSuggestions(SuggestionBox);

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const SearchTooltipUI = styled(SearchTooltip)`
  flex: 0 0 auto;
  max-width: 186px;
`;

const ProductListUI = styled(ProductList)`
  border-left: 1px solid #dbdbdb;
  flex: 1 1 75%;
  padding: 8px 0 10px 10px;
`;
