import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Stars = ({ rating, size = 18, color = '#f2c832', gray = '#ccc', gapBetweenStars = 0 }) => {
  if (rating === undefined) {
    return null;
  }

  const renderStars = (color, index) => (
    <svg key={`${color}-${index}`} height={size} viewBox="0 0 50 50" width={size}>
      <polygon
        fill={color}
        points="25,3.553 30.695,18.321 46.5,19.173   34.214,29.152 38.287,44.447 25,35.848 11.712,44.447 15.786,29.152 3.5,19.173 19.305,18.321 "
      />
    </svg>
  );

  return (
    <Wrapper width={size * 5} height={size} gapBetweenStars={gapBetweenStars}>
      <FilledStars rating={(rating * 100) / 5} gapBetweenStars={gapBetweenStars}>
        {Array.from({ length: 5 }).map((_, index) => renderStars(color, index))}
      </FilledStars>
      <GrayStars gapBetweenStars={gapBetweenStars}>
        {Array.from({ length: 5 }).map((_, index) => renderStars(gray, index))}
      </GrayStars>
    </Wrapper>
  );
};

Stars.propTypes = {
  rating: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  gray: PropTypes.string,
  gapBetweenStars: PropTypes.number,
};

const Wrapper = styled.div`
  display: inline-block;
  width: ${props => `${props.width + props.gapBetweenStars * 4}px`};
  height: ${props => `${props.height}px`};
  position: relative;
  display: flex;
  align-items: center;
`;

const FilledStars = styled.div`
  z-index: 1;
  width: ${props => `${props.rating}%`};
  overflow: hidden;
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  height: 100%;

  svg:not(:last-child) {
    margin-right: ${props => `${props.gapBetweenStars}px`};
  }
`;

const GrayStars = styled.div`
  display: inline-block;
  white-space: nowrap;
  height: 100%;

  svg:not(:last-child) {
    margin-right: ${props => `${props.gapBetweenStars}px`};
  }
`;
