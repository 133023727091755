import { getIn } from 'seamless-immutable';

export const handleDescription = product => {
  const type = getIn(product, ['description', 'type'], '');
  const description = getIn(product, ['description'], {});

  if (type === 'string') {
    return htmlToText(description.content);
  } else if (type === 'url') {
    return htmlToText(description.rawDescription);
  }
  return null;
};

const htmlToText = htmlDescription => {
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = htmlDescription;
  return htmlObject.textContent;
};
