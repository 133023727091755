import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

const Banner = ({ link, alt, image, className }) => {
  if (!image) {
    return null;
  }

  if (link) {
    return (
      <Nav to={link} className={className}>
        <Image src={image} alt={alt} />
      </Nav>
    );
  }

  return <Image src={image} alt={alt} className={className} />;
};

export default Banner;

const Image = styled.img`
  width: auto;
  height: 100%;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
`;
