import React from 'react';
import { getTarget, getRel, getHighlight } from '../helpers/trending-topics';
import { TrendingTopics, WithTrendingTopicsProps } from '../types';

export const withTrendingTopics = (Component: React.FC) => (props: WithTrendingTopicsProps) => {
  const tts = props?.trendingTopics?.component?.children || [];

  const trendingTopics =
    tts instanceof Array &&
    tts.map((tt: TrendingTopics) => ({
      highlight: getHighlight(tt),
      flag: tt?.flag || false,
      link: tt?.link || '/',
      title: tt?.title || '',
      target: getTarget(tt),
      rel: getRel(tt),
    }));

  const componentProps = {
    ...props,
    trendingTopics,
  };

  return <Component {...componentProps} />;
};

export default withTrendingTopics;
