import React from 'react';
import styled from 'styled-components';
import { ListPrice } from '../atoms/price';

const PriceInfo = ({ price, className, stamps }) => (
  <Wrapper className={className}>
    <ListPrice {...price} />
  </Wrapper>
);

export default PriceInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 5px;
`;
