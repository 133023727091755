import React from 'react';
import styled, { css } from 'styled-components';
import Overlay from '@catalogo/theme-overlay';

const TooltipBox = ({
  children,
  color,
  show,
  arrowPosition,
  zIndex,
  arrowSize,
  width,
  positionType,
  marginTop,
  top,
  right,
  left,
  bottom,
  onMouseEnter,
  onMouseLeave,
  close = () => {},
  hasOverlay,
}) => (
  <>
    <Content
      arrowPosition={arrowPosition}
      zIndex={zIndex}
      color={color}
      arrowSize={arrowSize}
      width={width}
      postionType={positionType}
      marginTop={marginTop}
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      show={show}
    >
      {children}
    </Content>
    {hasOverlay && <Overlay zIndex={zIndex - 1} close={close} showOverlay={show} />}
  </>
);

TooltipBox.defaultProps = {
  arrowPosition: '50%',
  show: true,
  hasOverlay: true,
  zIndex: 8,
  color: '#fff',
  arrowSize: 12,
  width: '100%',
  postionType: 'relative',
  marginTop: '10px',
};

export default TooltipBox;

const Content = styled.div`
  ${({ arrowPosition, zIndex, color, arrowSize, width, postionType, marginTop, top, right, left, bottom, show }) => css`
    width: ${width};
    box-sizing: border-box;
    height: ${show ? 'auto' : '0px'};
    content-visibility: ${show ? 'visible' : 'hidden'};
    position: ${postionType};
    z-index: ${zIndex};
    background-color: ${color};
    margin-top: ${marginTop};
    transition: all 0.3s;
    visibility: ${show ? 'visible' : 'hidden'};
    opacity: ${show ? 1 : 0};
    border-radius: 16px;
    pointer-events: ${show ? 'initial' : 'none'};
    box-shadow: 0px 0px 15px rgb(0 0 0 / 12%);
    ${top &&
    css`
      top: ${show ? top : `calc(${top} + 20px)`};
    `};
    ${right &&
    css`
      right: ${right};
    `};
    ${left &&
    css`
      left: ${left};
    `};
    ${bottom &&
    css`
      bottom: ${bottom};
    `};

    &:after {
      content: '';
      border-bottom: solid ${arrowSize}px ${color};
      border-left: solid ${arrowSize}px transparent;
      border-right: solid ${arrowSize}px transparent;
      position: absolute;
      top: -${arrowSize}px;
      left: ${arrowPosition};
    }
  `}
`;
