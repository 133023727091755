import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'seamless-immutable';
import { NavLink } from 'react-router-dom';
import { withConfig } from '@catalogo/core-connect/config';
import Url from 'url-parse';
import { isSupportedPath } from './helpers';

const Nav = ({ children, to, target, className, onClick, config, replace }) => {
  const spa = getIn(config, ['spa'], true);
  const brand = getIn(config, ['brandNameLower'], '');
  const url = new Url(to);

  if (spa && isSupportedPath(url, brand)) {
    const path = url.pathname + url.query + url.hash;

    return (
      <NavLink replace={replace} className={className} to={path} target={target} onClick={onClick}>
        {children}
      </NavLink>
    );
  }

  return (
    <a className={className} target={target} aria-current="page" href={to} onClick={onClick}>
      {children}
    </a>
  );
};

Nav.propTypes = {
  children: PropTypes.node,
  replace: PropTypes.bool,
  config: PropTypes.object,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const NavConnected = withConfig(Nav);

export { NavConnected as Nav };
