import React from 'react';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { useHeaderContext, setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';

const ContextSearch = ({ className }) => {
  const { name, context, slug } = useHeaderContext();
  const options = [
    { name, slug },
    { name: 'Americanas', slug: 'americanas' },
  ];
  const handleSeleted = e => {
    setHeaderContext({
      context: e.currentTarget.value,
    });
  };

  return (
    <ContextContainer>
      <SelectUI onChange={handleSeleted} className={className}>
        {options.map(({ name, slug }) => (
          <option key={slug} value={slug} selected={slug === context}>
            {name}
          </option>
        ))}
      </SelectUI>
      <ArrowUI />
    </ContextContainer>
  );
};

export default ContextSearch;

const ContextContainer = styled.div`
  position: relative;
  background: #e5e5e5;
  border-radius: 8px 0 0 8px;

  min-width: 176px;
`;

const SelectUI = styled.select`
  appearance: none;
  background: none;
  padding: 12px 33px 12px 15px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  color: ${({ theme }) => theme.grey.dark};
  &::first-letter {
    text-transform: capitalize;
  }
`;
const ArrowUI = styled(Arrow)`
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  width: 11px;
  fill: ${({ theme }) => theme.grey.medium};
`;
