import React from 'react';
import styled from 'styled-components';
import CardImage from '../atoms/card-image';

const ProductInfo = ({ className, ...cardProps }) => (
  <Wrapper className={className}>
    <CardImage {...cardProps} />
  </Wrapper>
);

export default ProductInfo;

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  aspect-ratio: 1 / 1;
`;
