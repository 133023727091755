import { matchPath } from 'react-router-dom';
import appRoutes from '@catalogo/shell-web/src/routes';

export const isSupportedPath = (url, brand) => {
  const isSameDomain = url.host.includes(brand);

  if (!isSameDomain) {
    return false;
  }

  return appRoutes.filter(route => route.path !== '/*').some(route => matchPath(url.pathname, route));
};
