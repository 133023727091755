import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Wrapper } from '@catalogo/theme-wrapper';
import Overlay from '@catalogo/theme-overlay';
import Menu from '@catalogo/ui-americanas-desktop-svg/menu-desk.svg';
import ArrowDown from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import withMenu from '@catalogo/service-americanas-header/src/hocs/menuDesk';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import { timerEventMetric } from '@catalogo/service-americanas-header/src/helpers/metrics';
import { AllDepartments } from './components/all-departments';
import { MenuTrendingDepartments } from './components/menu-trending-departments';

let timeout = 0;

export const DepartmentMenu = ({ mainDepartments, trendingDepartments, imageData, dispatchMetrics }) => {
  const [isDepartmentHovered, setIsDepartmentHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [domHeight, setDomHeight] = useState(0);
  const [canFireDispatchMetrics, setCanFireDispatchMetrics] = useState(true);
  const debounced = useDebounce(hover, hover ? 300 : 0);

  useEffect(() => {
    setIsDepartmentHovered(debounced);
    setIsOpen(debounced);
    setDomHeight(document?.getElementsByTagName('body')[0]?.offsetHeight);
  }, [debounced]);

  const mouseEnter = () => {
    setHover(true);
    if (canFireDispatchMetrics) {
      timeout = timerEventMetric(dispatchMetrics, 'menutemp', 'menu-header', 'menu-lateral', () =>
        setCanFireDispatchMetrics(false)
      );
    }
  };

  const mouseLeave = () => {
    setHover(false);
    clearTimeout(timeout);
  };

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) {
      mouseLeave();
    }
  };

  return (
    <Container zIndex={isOpen ? 10 : 1}>
      <WrapperUI>
        <DropdownWrapper
          onMouseLeave={() => mouseLeave()}
          onMouseEnter={() => mouseEnter()}
          onFocus={() => mouseEnter()}
          onKeyDown={onKeyDown}
        >
          <MenuWrapper>
            <ButtonWrapper>
              <MenuUI $isHovered={isDepartmentHovered} />
              <ButtonUI className="button" $isHovered={isDepartmentHovered}>
                todos os departamentos
              </ButtonUI>
              <ArrowDownUI $isHovered={isDepartmentHovered} />
            </ButtonWrapper>
          </MenuWrapper>
          <AllDepartments
            isHovered={isDepartmentHovered}
            departments={mainDepartments}
            zIndex={10}
            imageData={imageData}
            dispatchMetrics={dispatchMetrics}
          />
        </DropdownWrapper>
        <MenuTrendingDepartments
          trendingDepartments={trendingDepartments}
          triggerOverlay={setIsOpen}
          dispatchMetrics={dispatchMetrics}
        />
      </WrapperUI>
      <Overlay showOverlay={isOpen} zIndex={9} type="absolute" top="40px" height={isOpen ? `${domHeight}px` : `0px`} />
    </Container>
  );
};

export default withMenu(DepartmentMenu);

const Container = styled.div`
  ${({ zIndex }) => css`
    background: #fff;
    width: 100%;
    z-index: ${zIndex};
    position: relative;
    transition: all 0.3s;
  `}

  @media screen and (max-width: 1260px) {
    padding: 0 30px;
  }
`;

const WrapperUI = styled(Wrapper)`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const MenuUI = styled(Menu)`
  height: 16px;
  width: 24px;

  path {
    transition: fill 0.3s;
    fill: ${({ $isHovered, theme }) => ($isHovered ? theme.primaryColor : '#333')};
  }
`;

const ButtonUI = styled.button`
  font-weight: bold;
  color: ${({ $isHovered, theme }) => ($isHovered ? theme.primaryColor : '#333')};
  size: ${({ theme }) => theme.fontSize.xs};
  background: none;
  border: none;
  flex-wrap: nowrap;
  padding-left: 8px;
  padding-right: 11px;
  transition: color 0.3s;
  font-size: 13.6px;
`;

const ArrowDownUI = styled(ArrowDown)`
  height: 5px;
  width: 10px;
  fill: ${({ $isHovered, theme }) => ($isHovered ? theme.primaryColor : '#333')};
  transform: ${({ $isHovered }) => ($isHovered ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: fill 0.3s, transform 0.3s;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  background-color: #fff;
  transform: translateX(-12px);
  padding: 0 12px;
  width: auto;
  flex: 0 0 auto;
`;

const DropdownWrapper = styled.div`
  flex: 0 0 auto;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 40px;
  width: auto;
  flex: 0 0 auto;
`;
