import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import withHistoric from '@catalogo/service-americanas-header/src/hocs/historic';
import TootipBox from '@catalogo/theme-tooltip-box';
import Overlay from '@catalogo/theme-overlay';
import SearchTooltip from './search-tooltip';

export const HistoricBox = ({ data, show, showTooltipBox, zIndex }) => {
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    setSearchHistory(data.slice(0, 5));
  }, [data]);

  return (
    <Container>
      {searchHistory.length > 0 ? (
        <TootipBox
          show={show}
          close={showTooltipBox}
          positionType="absolute"
          top="10px"
          left="0"
          arrowPosition="30px"
          zIndex={zIndex}
        >
          <SearchTooltip title="Você buscou:" items={searchHistory} type="historic" />
        </TootipBox>
      ) : (
        <Overlay zIndex={zIndex - 1} close={showTooltipBox} showOverlay={show} />
      )}
    </Container>
  );
};

export default withHistoric(HistoricBox);

const Container = styled.div`
  position: relative;
`;
