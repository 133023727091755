export const fmtSuggestionItem = (item, term) => {
  const keySearch = term ? 'acterm' : 'achistory';
  const path = `/busca?conteudo=${item.term}&chave_search=${keySearch}`;
  const name = item.term.replace(/-/g, ' ');
  return { ...item, path, name };
};

export const fmtSuggestions = data => {
  if (Array.isArray(data)) {
    return data.map(fmtSuggestionItem, true);
  }

  return [];
};
