import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDebounce } from '@catalogo/core-hooks/debounce';
import { setTermSearched } from '@catalogo/service-americanas-header/src/helpers/keyboad-navigation';
import { getHeaderAutocomplete } from '../queries/autocomplete.graphql';
import { getHeaderSuggestions } from '../queries/suggestions.graphql';
import { getRecentSearches } from '../queries/localstorage.graphql';
import { fmtSuggestions } from '../helpers/autocomplete';

export const useAutocomplete = (term: string, skip: boolean, source = 'eltucomplete') => {
  const [searchTerm, setSearchTerm] = useState(term);

  const debouncedTerm = useDebounce(searchTerm, 800);

  useEffect(() => {
    setSearchTerm(term);
  }, [term]);

  const { loading, data, error } = useQuery(getHeaderAutocomplete, {
    variables: {
      term: encodeURIComponent(debouncedTerm),
      source,
    },
    skip: !debouncedTerm || skip,
    ssr: false,
  });

  const suggestions = fmtSuggestions(data?.autocomplete?.suggestions);

  return { loading, data: suggestions, error };
};

export const useSuggestions = (term: string, sellerId: string, skip: boolean, source = 'eltucomplete') => {
  const debouncedTerm = useDebounce(term, 500);
  const [suggestions, setSuggestions] = useState('');

  useEffect(() => {
    setSuggestions(term);
  }, [debouncedTerm]);

  const { loading, data, error, previousData } = useQuery(getHeaderSuggestions, {
    variables: {
      term: encodeURIComponent(suggestions),
      sellerId,
      source,
    },
    skip: !suggestions || !debouncedTerm || skip,
    ssr: false,
    onCompleted: () => {
      setTermSearched(term);
    },
  });

  return { loading, data, error, previousData };
};

export const useHistoric = () => {
  const { data } = useQuery(getRecentSearches);
  const recentSearches = data?.recentSearches || [];
  return recentSearches;
};
