import React from 'react';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';
import { useGetAddress } from '../hooks/get-address';
import { WithHeaderLocationProps } from '../types';

export const withHeaderLocation = (Component: React.FC) => (props: WithHeaderLocationProps) => {
  const { cookies, setCookie } = props;
  const address = useGetAddress(cookies, setCookie);

  const componentProps = {
    address,
    b2wRegion: cookies?.b2wRegion,
  };

  return <Component {...componentProps} {...props} />;
};

export default compose(withCookies, withHeaderLocation);
