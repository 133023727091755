import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';
import { Stars } from '@catalogo/theme-stars';

const ProductRating = ({ rating, size, color, fontSize, hasCount = true }) => {
  const renderCount = () => {
    const reviews = getIn(rating, ['reviews']);

    return (
      <Count color={color} fontSize={fontSize}>
        {!reviews ? 'sem' : reviews} avaliaç{reviews === 1 ? 'ão' : 'ões'}
      </Count>
    );
  };

  return (
    <Container size={size}>
      <Stars rating={getIn(rating, ['average'], 0)} size={size} />
      {hasCount && renderCount()}
    </Container>
  );
};

ProductRating.propTypes = {
  rating: PropTypes.object,
  size: PropTypes.number,
};

export default ProductRating;

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ size }) => `${size + 4}px`};
`;

const Count = styled.span`
  margin-left: 3px;
  font-weight: 300;

  ${({ theme, color, fontSize }) => css`
    color: ${color ? color : theme.grey.light};
    font-size: ${fontSize ? fontSize : theme.fontSize.xxs};
  `};

  @media screen and (max-width: 374px) {
    margin-left: 2px;
    font-size: 10px;
  }
`;
