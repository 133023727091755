import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const ListPrice = ({ promotionalPrice, className }) =>
  !!promotionalPrice && <PromotionalPrice className={className}>{promotionalPrice}</PromotionalPrice>;
const PromotionalPrice = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.lg};
    font-weight: 700;
  `}
`;

export default ListPrice;
