import React from 'react';
import styled from 'styled-components';
import Rating from '@catalogo/ui-americanas-desktop-rating';
import ProductName from '../atoms/product-name';

const ProductInfo = ({ className, rating, ...cardProps }) => (
  <Wrapper className={className}>
    <ProductName className="product-name" {...cardProps} />
    <Rating rating={rating} color="#999999" fontSize="14px" hasCount={false} />
  </Wrapper>
);

export default ProductInfo;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  margin-bottom: 5px;
  .product-name {
    margin-bottom: 5px;
  }
`;
