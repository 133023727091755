import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const TrendingDepartmentsItemWithMenu = ({
  children,
  item,
  highlight,
  last,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  dispatchMetrics,
}) => (
  <Item
    last={last}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
    onClick={() =>
      dispatchMetrics('event:track', {
        event: 'c:click',
        action: 'click',
        category: 'menu-tts',
        label: item?.title,
      })
    }
  >
    <Text highlight={highlight}>
      <Link to={item?.link} highlight={highlight}>
        {item?.title}
      </Link>
    </Text>
    {children}
  </Item>
);

export default TrendingDepartmentsItemWithMenu;

const Item = styled.div`
  ${({ last }) => css`
    display: inline-block;
    color: #666;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    cursor: pointer;
    height: 100%;

    ${last &&
    css`
      font-weight: bold;
    `}
  `}
`;

const Text = styled.span`
  display: inline-block;
  position: relative;
  height: 40px;

  ${({ highlight }) => css`
    ${highlight &&
    css`
      &::after {
        content: '';
        background-color: #f80032;
        border-radius: 16px;
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        width: auto;
        height: 2px;
        z-index: 2;
        pointer-events: none;
      }
    `}
  `}
`;

const Link = styled(Nav)`
  ${({ highlight, theme }) => css`
    padding: 10px 12px;
    display: block;

    color: #666;
    text-decoration: none;

    &:hover,
    &:active {
      color: #f80032;
      text-decoration: none;
    }
    ${highlight &&
    css`
      color: ${theme.primaryColor} !important;
    `}
  `}
`;
