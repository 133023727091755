import React from 'react';
import query from '@catalogo/core-graphql-cache/src/local/one-day-delivery/one-day-delivery.graphql';
import { useApolloClient, useQuery } from '@apollo/client';
import { OneDayDeliveryQuery } from '@catalogo/core-types';

export interface WithOneDayDeliveryProps {
  oneDayDeliveryFiltered: boolean;
  setOneDayDeliveryFiltered: SetOneDayDeliveryFiltered;
}

type SetOneDayDeliveryFiltered = (value: boolean) => void;

export const withOneDayDelivery = (Component: React.FC<WithOneDayDeliveryProps>): React.FC<WithOneDayDeliveryProps> => {
  const InnerComponent = (props: WithOneDayDeliveryProps) => {
    const [oneDayDeliveryFiltered, setOneDayDeliveryFiltered] = useOneDayDeliveryFiltered();

    return (
      <Component
        {...props}
        oneDayDeliveryFiltered={oneDayDeliveryFiltered}
        setOneDayDeliveryFiltered={setOneDayDeliveryFiltered}
      />
    );
  };
  return InnerComponent;
};

export const useOneDayDeliveryFiltered = (): [boolean, SetOneDayDeliveryFiltered] => {
  const client = useApolloClient();

  const { data } = useQuery<OneDayDeliveryQuery>(query);
  const oneDayDeliveryFiltered = !!data?.oneDayDeliveryFiltered;

  const setOneDayDeliveryFiltered: SetOneDayDeliveryFiltered = value => {
    client.writeQuery({
      query: query,
      data: { oneDayDeliveryFiltered: value },
    });
  };

  return [oneDayDeliveryFiltered, setOneDayDeliveryFiltered];
};
