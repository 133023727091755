import { formatPrice } from '@catalogo/core-helpers/price';
import { getIn } from 'seamless-immutable';

export const getBestPaymentOption = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const bestPaymentOption = getIn(offers, ['bestPaymentOption']);

  const price = getIn(bestPaymentOption, ['price']);
  const type = getIn(bestPaymentOption, ['type']);
  const cashback = getIn(bestPaymentOption, ['cashback']);
  const cashBackValue = getIn(bestPaymentOption, ['minInstallment', 0, 'cashback', 'value']);
  const minInstallment = getIn(bestPaymentOption, ['minInstallment', 0]);
  const maxInstallment = getIn(bestPaymentOption, ['maxInstallment', 0]);
  const discountBestPayment = getIn(bestPaymentOption, ['discount', 'rate'], 0);

  return { minInstallment, maxInstallment, type, cashback, cashBackValue, price, discountBestPayment };
};

export const getInstallmentPhrase = product => {
  const { type, maxInstallment, minInstallment, price } = getBestPaymentOption(product);

  if (type === 'BOLETO') {
    return 'no boleto';
  }

  if (type === 'PIX') {
    return 'com pix';
  }

  const cardType = 'cartão de crédito';

  if (minInstallment && minInstallment.value < price) {
    return `em 1x no ${cardType}`;
  }

  if (maxInstallment) {
    return `${maxInstallment.quantity}x de R$ ${formatPrice(maxInstallment.value)} sem juros no ${cardType}`;
  }

  return null;
};

export const getPrice = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const salesPrice = getIn(offers, ['salesPrice']);
  const listPrice = getIn(offers, ['listPrice']);
  const discount = getDiscount(product);
  const { price, minInstallment } = getBestPaymentOption(product);

  // Preço com desconto no parcelamento do melhor meio de pagamento.
  if (minInstallment && minInstallment.value < salesPrice) {
    return `R$ ${formatPrice(salesPrice)}`;
  }

  // Boleto e PIX com desconto direto no melhor meio de pagamento.
  if (discount && price !== salesPrice && ((!listPrice && salesPrice) || salesPrice < listPrice)) {
    return `R$ ${formatPrice(salesPrice)}`;
  }

  // Desconto direto na oferta.
  return listPrice ? `R$ ${formatPrice(listPrice)}` : null;
};

export const getPriceRange = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const salesPriceRange = getIn(offers, ['salesPriceRange'], [0, 0]);
  if (salesPriceRange?.length > 0) {
    return `entre <strong>R$${formatPrice(salesPriceRange[0])}</strong> e <strong>R$${formatPrice(
      salesPriceRange[1]
    )}</strong>`;
  }
  return null;
};

export const getPromotionalPriceValue = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const salesPrice = getIn(offers, ['salesPrice']);
  const { price, minInstallment } = getBestPaymentOption(product);

  if (minInstallment && minInstallment?.value < salesPrice) {
    return minInstallment?.value;
  }
  return price;
};

export const getPromotionalPrice = product => `R$ ${formatPrice(getPromotionalPriceValue(product))}`;

export const getDiscountRateText = rate => (rate >= 5 ? `${rate}%` : 'baixou');

export const getDiscount = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const { minInstallment, discountBestPayment } = getBestPaymentOption(product);
  const minInstallmentRate = getIn(minInstallment, ['discount', 'rate'], 0);

  const offerDiscountRate = getIn(offers, ['discount', 'rate'], 0);

  if (minInstallmentRate && minInstallmentRate > 0) {
    return getDiscountRateText(minInstallmentRate);
  }

  if (discountBestPayment && discountBestPayment > 0) {
    return getDiscountRateText(discountBestPayment);
  }

  if (offerDiscountRate && offerDiscountRate > 0) {
    return getDiscountRateText(offerDiscountRate);
  }

  return null;
};

export const getCashBack = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const { cashback, cashBackValue } = getBestPaymentOption(product);

  if (cashback && cashback.value > 0 && cashback.isSuperCashback) {
    return `R$ ${formatPrice(cashBackValue)}`;
  }
  return null;
};
