export const getAdditionalInformation = product => {
  const { diffs } = product;

  if (!diffs) {
    return null;
  }

  const colorVariations = diffs.find(diff => diff.type === 'Cor');

  const sizeVariations = diffs.find(diff => diff.type === 'Tamanho');

  if (colorVariations && colorVariations.values.length > 1) {
    return `${colorVariations.values.length} cores disponíveis`;
  }

  if (sizeVariations && sizeVariations.values.length > 1) {
    return `${sizeVariations.values.length} tamanhos disponíveis`;
  }

  return null;
};
