/* eslint-disable max-lines-per-function */
import React from 'react';
import { getIn } from 'seamless-immutable';
import compose from 'lodash/fp/compose';
import { withOneDayDelivery } from '@catalogo/core-connect/one-day-delivery';
import { withRouter } from 'react-router';
import { withMetrics } from '@catalogo/core-metrics';
import { withPageType } from '@catalogo/core-connect/page-type';
import { withConfig } from '@catalogo/core-connect/config';
import {
  getEventStampUrl,
  getImage,
  isAds,
  isAdsStamps,
  isFastDelivery,
  isInternational,
  isPreSale,
  isRecommended,
  isSuperCashback,
  isUsed,
  oneDayDeliveryMsg,
  shouldRenderFreeFreight,
} from '../helpers/image-and-stamps';
import { getAdditionalInformation } from '../helpers/sku-variations';
import {
  getLink,
  getOneDayDelivery,
  getPfmParams,
  getPfmParamsAds,
  getPfmParamsRecs,
  getPfmParamsMercado,
} from '../helpers/url-builder';
import {
  getCashBack,
  getDiscount,
  getInstallmentPhrase,
  getPrice,
  getPriceRange,
  getPromotionalPrice,
} from '../helpers/price-values';
import { handleMetrics } from '../helpers/metrics';
import { handleDescription } from '../helpers/description';
import { isSellerPage, getSellerId } from '../helpers/seller';
import { formatProductRec } from '../helpers/format-product';
import { WithProductCardProps } from '../types';

export const withProductCard = (Component: React.FC) => (props: WithProductCardProps) => {
  const {
    id,
    oneDayDeliveryFiltered,
    positionCard,
    match,
    position,
    dispatchMetrics,
    index,
    isRec,
    pageType,
    publication,
    trackingUrl,
    queryString,
    isAd,
    config,
    error,
    isBannerVitrineAds,
  } = props;
  const productToFormat = getIn(props, ['product', 'product'], props?.product) || {};
  const sellerId = isSellerPage(pageType) ? getSellerId(productToFormat) : '';
  const objectToFormat = {
    product: { id, ...productToFormat },
    publication,
    position,
    pageType,
    trackingUrl,
    queryString,
    isAd,
  };

  const product = isRec ? formatProductRec(objectToFormat) : productToFormat;
  const isLoading = isRec ? !product?.name && !error : !product?.name;
  const offer = getIn(product, ['offers', 'result', 0], null);

  const isMarketProduct = product?.offers?.result.some((item: Record<string, string>) =>
    item?.categories?.includes('MARKET')
  );

  const sendMetrics = () =>
    handleMetrics(product, position, positionCard ?? index, dispatchMetrics, isBannerVitrineAds);

  const pfmParams = () => {
    if (isRec) {
      return getPfmParamsRecs(positionCard ?? index, position, publication, pageType);
    }

    if (isAdsStamps(product)) {
      const { position, positionCard, publication } = product.pfm;
      return getPfmParamsAds(positionCard ?? index, position, publication, pageType);
    }

    if (isMarketProduct) {
      return getPfmParamsMercado(match, positionCard ?? index, pageType);
    }

    return getPfmParams(match, positionCard ?? index, pageType);
  };

  const oneDayDeliveryParam = getOneDayDelivery(oneDayDeliveryFiltered);

  const isNurslingProduct = product?.compliance?.nursling;

  const cardProps = {
    isLoading,
    name: product.name,
    rating: product.rating,
    outOfStock: !offer,
    skuVariations: getAdditionalInformation(product),
    imageSource: getImage(product),
    url: getLink(product, pfmParams(), oneDayDeliveryParam, sellerId, isRec, isMarketProduct, product?.attributes),
    oneDayDeliveryMessage: oneDayDeliveryMsg(product),
    handleClick: sendMetrics,
    description: handleDescription(product),
    seller: offer?.seller,
    isMarketProduct,
    isNurslingProduct,
    price: {
      installmentPhase: getInstallmentPhrase(product),
      price: getPrice(product, isNurslingProduct),
      promotionalPrice: getPromotionalPrice(product),
      discount: getDiscount(product, isNurslingProduct),
      cashback: getCashBack(product, isNurslingProduct),
      range: getPriceRange(product),
    },
    stamps: {
      eventStamp: getEventStampUrl(product, config?.brand, isNurslingProduct),
      discountProgressiveStamp: isNurslingProduct ? null : product?.offers?.result?.[0]?.nextProgressiveDiscount,
      isRecommended: isRecommended(product, isNurslingProduct),
      isAd: isRec ? isAdsStamps(product) : isAds(product),
      isPreSale: isPreSale(product),
      isUsed: isUsed(product),
      isSuperCashback: isSuperCashback(product, isNurslingProduct),
      isInternational: isInternational(product),
      isFreeFreight: shouldRenderFreeFreight(product),
      isFastDelivery: isFastDelivery(product),
    },
  };

  return <Component {...props} {...cardProps} />;
};

export default compose(withConfig, withRouter, withMetrics, withOneDayDelivery, withPageType, withProductCard);
