/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import FreightField from '@catalogo/ui-americanas-desktop-freight-field';
import Button from '@catalogo/ui-americanas-desktop-button';
import TooltipBox from '@catalogo/theme-tooltip-box';
import { useGetCoord } from '@catalogo/service-americanas-header/src/hooks/get-address';
import { addMonths } from '@catalogo/core-cookies/src/helpers';

let hasFocusInput = false;
let timer = 0;
let firstLoad = true;

const showTooltipTimer = (show, func) => {
  clearTimeout(timer);
  if (show && firstLoad) {
    timer = setTimeout(() => {
      func();
    }, 2000);
  }
  firstLoad = false;
};

const Tooltip = ({ fetchCoordinates, showError, geoLoading, setCep, login, dispatchMetrics, error }) => (
  <TooltipWrapper>
    <Text>para ver os melhores fretes e prazos para sua região</Text>
    <Text>digite seu CEP</Text>
    <FreightFieldUI
      handleSubmit={fetchCoordinates}
      placeholder="_____-___"
      showZipCodeAlert={showError}
      loading={geoLoading}
      onFocus={() => (hasFocusInput = true)}
      onBlur={() => (hasFocusInput = false)}
      setCep={setCep}
      dispatchMetrics={dispatchMetrics}
      error={error}
    />
    <TextBottom>acesse os endereços cadastrados</TextBottom>
    <LoginButton title="fazer login" href={login} />
  </TooltipWrapper>
);

const CepTooltip = ({ show, setShowTooltip, setCookie, cookies, login, hasOverlay, address, dispatchMetrics }) => {
  const [cep, setCep] = useState(cookies?.persistentCep || '');
  const [showError, setShowError] = useState(null);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { fetchCoordinates, geoData, geoLoading, error } = useGetCoord({ cep });
  useEffect(() => {
    showTooltipTimer(show, () => {
      if (!hasFocusInput && !isMouseEnter) {
        setShowTooltip(false);
      }
    });
  }, [isMouseEnter]);

  useEffect(() => {
    if (geoData) {
      if (geoData.region) {
        setShowTooltip(false);
        setCookie('persistentCep', cep, addMonths(new Date(), 12));
      } else {
        setShowError(geoData.region === null);
      }
    }
  }, [geoData]);

  if (address) {
    return null;
  }

  const toggleTooltip = value => {
    setShowTooltip(value);
    if (!value) {
      setShowError(false);
    }
  };

  return (
    <Container>
      <TooltipBox
        positionType="absolute"
        width="300px"
        top="35px"
        left="-31px"
        height="auto"
        color="#fff"
        hasOverlay={!!hasOverlay || !!isMouseEnter}
        arrowPosition="30px"
        onMouseLeave={() => {
          if (!hasFocusInput) {
            toggleTooltip(false);
          }
          setIsMouseEnter(false);
        }}
        show={show}
        zIndex={show ? 9 : 0}
        onMouseEnter={() => {
          setIsMouseEnter(true);
          toggleTooltip(true);
        }}
        close={() => toggleTooltip(false)}
      >
        <Tooltip
          fetchCoordinates={fetchCoordinates}
          showError={showError}
          geoLoading={geoLoading}
          setCep={setCep}
          login={login}
          dispatchMetrics={dispatchMetrics}
          error={error}
        />
      </TooltipBox>
    </Container>
  );
};

export default CepTooltip;

const Container = styled.div`
  position: absolute;
`;

const TooltipWrapper = styled.div`
  min-height: 199px;
  padding: 20px 25px 30px 25px;
  position: relative;
  justify-content: center;
`;

const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: 15px;
    margin-bottom: ${theme.spacing.sm};
  `}
`;

const TextBottom = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: 15px;
    margin: ${theme.spacing.sm} 0;
  `}
`;

const FreightFieldUI = styled(FreightField)`
  & input::placeholder {
    color: ${({ theme }) => theme.grey.medium};
  }
`;

const LoginButton = styled(Button)`
  background: #f80032;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 41px;
  border-radius: 4px;

  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
  }
`;
