import React from 'react';
import styled from 'styled-components';
import { Text } from '@catalogo/theme-text';
import Alert from '@catalogo/ui-shoptime-desktop-svg/alert.svg';

const AlertWarning = () => (
  <AlertUI>
    <Alert fill="#f2c832" height="20" width="3" />
    <TextWarning>CEP não encontrado.</TextWarning>
  </AlertUI>
);

const AlertUI = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: ${({ theme }) => theme.warning.light};
  margin-top: 12px;
  border-radius: 4px;
  padding-left: 20px;
`;

const TextWarning = styled(Text)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize['sm']};
  line-height: 18px;
  color: ${({ theme }) => theme.grey.darker};
  margin-left: 10px;
`;

export default AlertWarning;
