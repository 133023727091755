import { setHeaderContext } from '@catalogo/service-americanas-header/src/context/header';

export const resetTermSearched = () => {
  setHeaderContext({ termSearched: '', termSelected: '', tooltipItemSelected: -1 });
};

export const setTermSearched = term => {
  setHeaderContext({
    tooltipItemSelected: -1,
    termSelected: term,
    termSearched: term,
  });
};

export const resetTooltipItemSelected = termSearched => {
  setHeaderContext({
    tooltipItemSelected: -1,
    termSelected: termSearched,
  });
};

export const resetProductItemSelected = termSearched => {
  setHeaderContext({
    productItemSelected: -1,
  });
};

export const setTermSelected = typedTerm => {
  setHeaderContext({
    termSelected: typedTerm,
  });
};
