import React from 'react';
import styled, { css } from 'styled-components';

const Overlay = ({ showOverlay, zIndex, close, height = '100vh', type = 'fixed', top = '0' }) => (
  <OverlayTheme
    zIndex={zIndex}
    onClick={() => close && close(false)}
    showOverlay={showOverlay}
    height={height}
    type={type}
    top={top}
  />
);

export default Overlay;

const OverlayTheme = styled.div`
  ${({ zIndex, showOverlay, type, top, height }) => css`
    background-color: rgba(0, 0, 0, 0.4);
    position: ${type};
    left: 0;
    right: 0;
    top: ${top};
    height: ${height};
    z-index: ${zIndex};
    transition: all 0.3s;
    cursor: default;
    visibility: ${showOverlay ? 'visible' : 'hidden'};
    opacity: ${showOverlay ? 1 : 0};
  `}
`;
