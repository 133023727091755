import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import BoxIcon from '@catalogo/ui-americanas-desktop-svg/box.svg';
import HeartIcon from '@catalogo/ui-americanas-desktop-svg/header-heart-desk.svg';
import TicketIcon from '@catalogo/ui-americanas-desktop-svg/ticket.svg';
import UserIcon from '@catalogo/ui-americanas-desktop-svg/user.svg';
import LocationIcon from '@catalogo/ui-americanas-desktop-svg/location-tooltip-header.svg';
import LogoutIcon from '@catalogo/ui-americanas-desktop-svg/logout.svg';
import AccountIcon from '@catalogo/ui-americanas-desktop-svg/account.svg';
import { addParamRandom } from './helper';

const ToolTipLogin = ({ isLogged, zIndex, history, config }) => {
  const url = `${config?.domainUrl}${history?.location?.pathname || ''}`;
  const qs = history?.location?.search || '';
  const currentUrl = `${url}${qs}`;

  if (isLogged) {
    return (
      <Container zIndex={zIndex}>
        <MyAccountLink to="https://cliente.americanas.com.br/minha-conta">
          <AccountIcon width={24} height={24} /> ver minha conta
        </MyAccountLink>
        <LoggedLink to="https://cliente.americanas.com.br/minha-conta/pedidos">
          <BoxIcon width={24} height={24} /> pedidos
        </LoggedLink>
        <LoggedLink to="https://www.americanas.com.br/listas/favoritos">
          <HeartIcon width={24} height={24} /> favoritos
        </LoggedLink>
        <LoggedLink to="https://cliente.americanas.com.br/minha-conta/vouchers">
          <TicketIcon width={24} height={24} /> vales
        </LoggedLink>
        <LoggedLink to="https://cliente.americanas.com.br/minha-conta/usuario">
          <UserIcon width={24} height={24} /> cadastro
        </LoggedLink>
        <LoggedLink to="https://cliente.americanas.com.br/minha-conta/endereco">
          <LocationIcon width={24} height={24} /> endereço
        </LoggedLink>
        <LoggedLink to={`https://cliente.americanas.com.br/minha-conta/sair?next=${addParamRandom(url, qs)}`}>
          <LogoutIcon width={24} height={24} /> sair da conta
        </LoggedLink>
      </Container>
    );
  }
  return (
    <Container zIndex={zIndex}>
      <Text>pra ver seus pedidos e ter uma experiência personalizada, acesse sua conta :)</Text>
      <Login to={`https://cliente.americanas.com.br/minha-conta/entrar?next=${currentUrl}`}>entrar</Login>
      <SignUp to={`https://cliente.americanas.com.br/minha-conta/cadastro?next=${currentUrl}`}>cadastrar</SignUp>
      <CenteredText>
        comprou na loja física ou no app da Ame?{' '}
        <CenteredLink to={`https://cliente.americanas.com.br/minha-conta/entrar?guest=true&next=${currentUrl}`}>
          Acompanhar o pedido
        </CenteredLink>
      </CenteredText>
    </Container>
  );
};

export default ToolTipLogin;

const Container = styled.div`
  width: 207px;
  color: #666;
  margin: 26px 30px;
  z-index: ${({ zIndex }) => zIndex};
`;
const Text = styled.span`
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 18px;
  display: block;
  -webkit-font-smoothing: antialiased;
`;

const Login = styled(Nav)`
  ${({ theme }) => css`
    border-radius: 8px;
    box-shadow: none;
    font-size: 16px;
    padding: 10px;
    font-weight: 700;
    background: ${theme.primaryColor};
    text-align: center;
    margin-bottom: 10px;
    color: #fff;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: filter 0.3s;

    &:hover,
    &:active {
      text-decoration: none;
      filter: opacity(75%);
    }
  `}
`;

const SignUp = styled(Login)`
  color: ${({ theme }) => theme.primaryColor};
  background: #fff;
  border: 2px solid ${({ theme }) => theme.primaryColor};

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const Link = styled(Nav)`
  ${({ theme }) => css`
    font-size: 16px;
    color: #666;
    fill: #666;
    text-decoration: underline;
    padding: 12px 0;
    display: block;
    transition: all 0.4s ease;

    &:hover {
      color: ${theme.primaryColor};
      fill: ${theme.primaryColor};
    }
  `}
`;

const CenteredLink = styled(Link)`
  text-align: center;
  font-size: 12px;
  padding: 0;
  display: inline;
`;

const CenteredText = styled.span`
  text-align: center;
  font-size: 12px;
  display: block;
`;

const LoggedLink = styled(Link)`
  text-decoration: none;
  align-items: center;
  display: flex;
  font-size: 16px;

  &:last-child {
    padding-bottom: 0;
  }

  & svg {
    margin-right: 8px;
  }
`;

const MyAccountLink = styled(LoggedLink)`
  padding: 5px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  stroke: #333;
  transition: all 0.4s ease;

  &:hover {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;
