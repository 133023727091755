export const timerEventMetric = (dispatchMetrics, event, category, label, callback) =>
  setTimeout(() => {
    dispatchMetrics('event:track', {
      event,
      action: 'mouseover',
      category,
      label,
    });
    if (callback) {
      callback();
    }
  }, 2000);
