/* istanbul ignore file */
import { makeVar } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';

const isClient = typeof window !== 'undefined';
const INITIAL_STATE = {
  name: 'Americanas',
  slug: 'americanas',
  formAction: '/busca',
  placeholder: 'busque aqui seu produto',
  context: '',
  tooltipItemSelected: -1,
  productItemSelected: -1,
  termSelected: '',
  termSearched: '',
  isFixed: false,
};

const headerContext = isClient ? makeVar(INITIAL_STATE) : INITIAL_STATE;

const useHeaderContext = isClient ? () => useReactiveVar(headerContext) : () => INITIAL_STATE;

const setHeaderContext = value => {
  const oldValue = headerContext();
  if (isClient) {
    headerContext({
      ...oldValue,
      ...value,
    });
  }
};
export { headerContext, useHeaderContext, setHeaderContext };
