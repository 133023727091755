import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';

const CardImage = ({ imageSource, name, className }) => (
  <WrapperPicture className={className}>
    <LazyPicture sources={imageSource} alt={name} />
  </WrapperPicture>
);

const WrapperPicture = styled.div`
  display: flex;
  justify-content: center;
  width: 106px;
`;

export default CardImage;
