import React, { HTMLProps } from 'react';
import { fmtDepartmentsData, titleIsDepartment, titleIsAllDepartments, getImageData } from '../helpers/header';
import { WithMenuProps } from '../types';

export const withMenu = (Component: React.FC) => ({
  trendingDepartments,
  menu,
  ...props
}: WithMenuProps & HTMLProps<HTMLSpanElement>) => {
  const mainDepartmentsData = menu?.component?.children ?? [];
  const mainDepartmentsDataFmt = fmtDepartmentsData(mainDepartmentsData);
  const departmentsTree = mainDepartmentsDataFmt.find(titleIsDepartment)?.children ?? [];
  const allDepartments = mainDepartmentsDataFmt.find(titleIsAllDepartments)?.children ?? [];

  const trendingDepartmentsData = trendingDepartments?.component?.children ?? [];
  const trendingDepartmentsDataFmt = fmtDepartmentsData(trendingDepartmentsData) ?? [];
  const imageData = getImageData(menu);

  const componentProps = {
    departmentsTree: departmentsTree,
    allDepartments: allDepartments,
    mainDepartments: mainDepartmentsDataFmt,
    imageData: imageData,
    trendingDepartments: trendingDepartmentsDataFmt,
  };

  return <Component {...componentProps} {...props} />;
};

export default withMenu;
