import { getIn } from 'seamless-immutable';

export const getImage = product => {
  const imagesMap = ['medium', 'big', 'large'];

  const sizeToRender = imagesMap.find(size => getIn(product, ['images', 0, size]) && size);

  const sources = {
    mobile: {
      src: getIn(product, ['images', 0, sizeToRender]),
      ratio: '1:1',
    },
    desktop: {
      src: getIn(product, ['images', 0, 'large']),
      ratio: '1:1',
    },
  };
  return sources;
};

export const getImageBrand = props => {
  const image = getIn(props, ['sponsorImage'], '');

  const sources = {
    mobile: {
      src: image,
      ratio: '1:1',
    },
    desktop: {
      src: image,
      ratio: '1:1',
    },
  };
  return sources;
};

export const getEventStampUrl = (product, brand, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const eventStamp = getIn(offers, ['stamps', 'events', 0], false);
  const brandName = getBrand(brand);
  return eventStamp ? `https://images-americanas.b2w.io/selob2c/${brandName.toLowerCase()}/${eventStamp}.png` : null;
};

export const isPrime = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const stamps = offers?.stamps?.result;
  return stamps?.includes('PRIME') || false;
};

export const isRecommended = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const stamps = offers?.stamps?.result;
  return stamps?.includes('INDICA') || false;
};

export const isPreSale = product => !!getIn(product, ['isPreSale'], false);

export const isAdsStamps = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  return getIn(offers, ['stamps', 'ad'], false);
};

export const isAds = product => !!getIn(product, ['ads'], false);

export const isBrand = props => {
  const brand = getIn(props, ['sponsorName'], '');
  return brand ? `patrocinado por ${brand}` : 'patrocinado';
};

export const isUsed = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  return getIn(offers, ['condition'], '') === 'USED';
};

export const isSuperCashback = (product, isNurslingProduct) => {
  if (isNurslingProduct) {
    return null;
  }

  const offers = getIn(product, ['offers', 'result', 0], {});
  const bestPaymentOption = getIn(offers, ['bestPaymentOption']);
  const cashback = getIn(bestPaymentOption, ['cashback']);

  return cashback && cashback.value > 0 && cashback.isSuperCashback;
};

export const isInternational = product => getIn(product, ['offers', 'result', 0, 'currency'], '') === 'USD';

export const isAdsType = product => getIn(product, ['type'], '') === 'ads';

export const shouldRenderFreeFreight = product => {
  const offer = getIn(product, ['offers', 'result', 0], {});
  const currencyIsDiffOfUSD = offer.currency !== 'USD';
  const isPrimeOrHasShippingFree = isPrime(product) || hasShippingFree(product);

  return currencyIsDiffOfUSD && isPrimeOrHasShippingFree;
};

export const hasShippingFree = product => {
  const offers = product?.offers?.result?.[0];
  const stamps = offers?.stamps?.result;
  return !!stamps?.includes('FRETE GRÁTIS');
};

export const oneDayDeliveryMsg = product => {
  const offers = getIn(product, ['offers', 'result', 0], {});
  const stamps = offers?.stamps?.result;
  const oneDayDelivery = stamps?.filter(removeStamps)?.[0];
  return oneDayDelivery ? oneDayDelivery : null;
};

export const removeStamps = stamp => {
  const stampsForRemove = {
    PRIME: false,
    INDICA: false,
    'FRETE GRÁTIS': false,
    'retire na loja': false,
  };

  return stampsForRemove[stamp] ?? true;
};

const validOneDayDeliveryMsgWithout__ = message => message.indexOf('__') === -1;

export const isFastDelivery = product =>
  oneDayDeliveryMsg(product) && validOneDayDeliveryMsgWithout__(oneDayDeliveryMsg(product));

const getBrand = brand => {
  switch (brand) {
    case 'suba':
      return 'submarino';
    case 'shop':
      return 'shoptime';
    default:
      return 'americanas';
  }
};
