import React from 'react';
import { Text } from '@catalogo/theme-text';
import styled from 'styled-components';

import { NoImage } from '../icons/no-image';

export const ErrorImage = ({ size }) => (
  <NoImageWrapper size={size}>
    <NoImage />
    <NoImageText>Imagem indisponível</NoImageText>
  </NoImageWrapper>
);

const NoImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  height: ${({ size }) => size || '162px'};
  padding: 3px;
  border-radius: 6px;
`;

const NoImageText = styled(Text)`
  margin-top: 15px;
  color: #9a9a9a;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
`;
